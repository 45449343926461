"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.map");

var state = {
  listPagePars: new Map()
};
var mutations = {
  SAVE_LIST_PAGE_PARS: function SAVE_LIST_PAGE_PARS(state, _ref) {
    var path = _ref.path,
        pars = _ref.pars;
    state.listPagePars.set(path, pars);
  }
};
var actions = {
  saveListPagePars: function saveListPagePars(_ref2, _ref3) {
    var commit = _ref2.commit;
    var path = _ref3.path,
        pars = _ref3.pars;
    commit('SAVE_LIST_PAGE_PARS', {
      path: path,
      pars: pars
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;