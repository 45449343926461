"use strict";

var _interopRequireDefault = require("/root/workspace/zyx2_zyx-eladmin-web_7xsg/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _store = _interopRequireDefault(require("@/store"));

var _default = {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var roles = _store.default.getters && _store.default.getters.roles;

    if (value && value instanceof Array && value.length > 0) {
      var permissionRoles = value;
      var hasPermission = roles.some(function (role) {
        return permissionRoles.includes(role);
      });

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("\u4F7F\u7528\u65B9\u5F0F\uFF1A v-permission=\"['admin','editor']\"");
    }
  }
};
exports.default = _default;